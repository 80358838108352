var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        lazy: "",
        "no-fade": "",
        "no-enforce-focus": "",
        "hide-footer": "",
        size: "md",
        title: _vm.$t("payments.titles.debitBalance"),
      },
      on: {
        hidden: _vm.handleClose,
        show: function ($event) {
          return _vm.getBalance()
        },
      },
      model: {
        value: _vm.cpHandlerModal,
        callback: function ($$v) {
          _vm.cpHandlerModal = $$v
        },
        expression: "cpHandlerModal",
      },
    },
    [
      _c(
        "b-container",
        { staticClass: "p-0" },
        [
          _c(
            "b-form-row",
            [
              _c("mask-input", {
                attrs: {
                  "mask-label": "Saldo na Carteira",
                  prepend: _vm.$t("payments.labels.currency"),
                  "parent-class": "col-md-5 col-sm-12",
                  disabled: "",
                  mask: ["####,##", "###,##", "##,##", "#,##"],
                },
                model: {
                  value: _vm.amount,
                  callback: function ($$v) {
                    _vm.amount = $$v
                  },
                  expression: "amount",
                },
              }),
            ],
            1
          ),
          _c(
            "b-form-row",
            [
              _c("mask-input", {
                attrs: {
                  "mask-label": "Valor do Lançamento",
                  prepend: _vm.$t("payments.labels.currency"),
                  "parent-class": "col-md-5 col-sm-12",
                  "data-test": "modal-transaction__input-amount-paid",
                  disabled: "",
                  mask: ["####,##", "###,##", "##,##", "#,##"],
                },
                model: {
                  value: _vm.amountWithDiscount,
                  callback: function ($$v) {
                    _vm.amountWithDiscount = $$v
                  },
                  expression: "amountWithDiscount",
                },
              }),
            ],
            1
          ),
          _c(
            "b-form-row",
            [
              _c(
                "b-form-group",
                {
                  staticClass: "col-md-12 col-sm-12",
                  attrs: {
                    label: _vm.$t("payments.labels.paymentComments"),
                    "label-for": "transaction-internal-comments",
                  },
                },
                [
                  _c("b-form-textarea", {
                    attrs: { type: "text", rows: 1, "max-rows": 4 },
                    model: {
                      value: _vm.useCreditInternalComments,
                      callback: function ($$v) {
                        _vm.useCreditInternalComments = $$v
                      },
                      expression: "useCreditInternalComments",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "float-right pt-2" },
            [
              _c(
                "b-button",
                {
                  staticClass: "ml-2",
                  attrs: { "data-test": "modal-transaction__btn-save" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("closeModalDebitBalance")
                    },
                  },
                },
                [_vm._v(" Cancel ")]
              ),
              _c(
                "b-button",
                {
                  staticClass: "ml-2",
                  attrs: {
                    variant: "primary",
                    "data-test": "modal-transaction__btn-save",
                    disabled: _vm.loading,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.confirmDebitCredits()
                    },
                  },
                },
                [
                  _vm.loading
                    ? _c("span", [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("payments.buttons.confirming")) +
                            " "
                        ),
                      ])
                    : _c("span", [
                        _vm._v(
                          " " + _vm._s(_vm.$t("payments.buttons.confirm")) + " "
                        ),
                      ]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }